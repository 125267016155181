import {AxiosRequestConfig, AxiosResponse} from "axios";
import cognitoService from "../libs/CognitoService";

const axios = require('axios');
const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    let accessToken = window.localStorage.getItem('accessToken');
    const isTokenExpired = await cognitoService.checkIfAccessTokenExpired(accessToken);
    if(isTokenExpired) {
      accessToken = await cognitoService.refreshAccessToken();
    }
    config.headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
    return config;
  },
  (error: any) => {
    throw error;
  });

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response: AxiosResponse) => response, async (error: any) => {
  const originalRequest = error.config;
  if (error?.response?.status === 403) {
    const accessToken = await cognitoService.refreshAccessToken();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});

export default axiosApiInstance;
