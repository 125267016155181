import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoRefreshToken,
  ICognitoUserPoolData,
} from "amazon-cognito-identity-js";
import jwtDecode from "jwt-decode";

class CognitoService {
  userPool: CognitoUserPool;
  currentUser: CognitoUser | null;

  constructor() {
    const poolData: ICognitoUserPoolData = {
      UserPoolId: process.env.REACT_APP_USERPOOL_ID || '',
      ClientId: process.env.REACT_APP_CLIENT_ID || ''
    };
    this.userPool = new CognitoUserPool(poolData);
    this.currentUser = this.userPool.getCurrentUser();
    // get current user directly from session info
  }

  async signIn(username: string, password: string) {
    const authenticationData = {
      Username: username,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return new Promise((resolve, reject) => {
      this.currentUser = this.getCognitoUser(username) as CognitoUser;
      // get user from cognito user pool
      this.currentUser.authenticateUser(authenticationDetails, {
        onSuccess: function (res: any) {
          resolve(res);
        },
        onFailure: function (error: any) {
          reject(error);
        },
      });
    }).catch((error) => {
      throw error;
    });
  }

  signOut() {
    if (this.currentUser) {
      this.currentUser.signOut();
    }
  }

  async getSession() {
    return new Promise((resolve, reject) => {
      this.currentUser = this.currentUser || this.userPool.getCurrentUser() as CognitoUser;
      this.currentUser.getSession(function (err: any, session: any) {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    }).catch((error) => {
      throw error;
    });
  }

  async getAttributes() {
    return new Promise( (resolve, reject) => {
      this.currentUser = this.currentUser || this.userPool.getCurrentUser() as CognitoUser;
      this.currentUser.getUserAttributes(function (err: any, attributes: any) {
        if (err) {
          reject(err);
        } else {
          resolve(attributes);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }
  async setAttribute(attribute: any) {
    return new Promise( (resolve, reject) => {
      const attributeList = [];
      const res = new CognitoUserAttribute(attribute);
      attributeList.push(res);

      this.currentUser = this.currentUser || this.userPool.getCurrentUser() as CognitoUser;
      this.currentUser.updateAttributes(attributeList, (err: any, res: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  private getCognitoUser(username: string ) {
    const userData = {
      Username: username,
      Pool: this.userPool,
    };
    return new CognitoUser(userData);
  }

  async refreshAccessToken() {
    return new Promise<string>(() => {
      const refreshToken = localStorage.getItem("refreshToken") || '';
      const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
      this.currentUser?.refreshSession(token, function (err, session) {
        const token = session.getIdToken().getJwtToken();
        localStorage.setItem("accessToken", token);
        return token;
      });
    });
  }

  async checkIfAccessTokenExpired(token: string | null) {
    const accessToken = token || localStorage.getItem("accessToken") || '';
    const decodedToken = jwtDecode(accessToken);

    // @ts-ignore
    return decodedToken.exp * 1000 < new Date().getTime();
  }
}

export default new CognitoService();


