import React, {FC} from 'react';
import {Typography} from 'antd';
import styled from "styled-components";

const { Text } = Typography;

const FooterContainer = styled.div`
  text-align: center;
  margin: 20px auto;
`;

const TelecareFooter: FC = () => (
  <FooterContainer>
    <Text>
      Copyright © 2020 telecareonline.com.au
    </Text>
  </FooterContainer>
);

export default TelecareFooter;
