import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {SIGN_IN} from "./routes/routes-constant";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import AuthIsSignedIn from "./routes/AuthIsSignedIn";

const App = () => {
  return (
    <Routes>
      <Route index element={
        <AuthIsSignedIn>
          <Dashboard />
        </AuthIsSignedIn>
      }/>
      <Route path={SIGN_IN} element={<SignIn />} />
    </Routes>
  );
};

export default App;
