import React, {FC} from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import SignInForm from './Form';
import TelecareFooter from '../../components/Footer';

const { Title, Text } = Typography;

const Spacer = styled.div<{height: number}>`
  height: ${(props) => props.height}px;
`;

const WelcomeToTelecareContainer = styled.div`
  text-align: center;
  margin-top: 60px;
`;

const Container = styled.div`
  width: 40%;
  margin: auto;
`;

const ImageContainer = styled.div`
  width: 190px;
  height: 60px;
  margin: 30px auto 80px;
`;

const SignIn: FC = () => {
  return (
    <Container>
      <ImageContainer>
        <img alt="logo" width="100%" src={process.env.PUBLIC_URL + '/assets/slogan.jpg'} />
      </ImageContainer>
      <main>
        <SignInForm />
        <WelcomeToTelecareContainer>
          <Title level={3}>Hi, Welcome to Telecare!</Title>
          <Spacer height={44} />
          <Text>
            Contact Telecare for Login Details at (03 9052 4872) or via live chat.
          </Text>
          <Spacer height={56} />
          <img src={process.env.PUBLIC_URL + '/assets/doctors.svg'} alt="Doctors" />
        </WelcomeToTelecareContainer>
      </main>
      <TelecareFooter />
    </Container>
  );
};

export default SignIn;
