import React, {FC, useContext, useState} from 'react';
import {Form, Input, Checkbox, Button, Alert} from 'antd';
import {PRIVACY_POLICY, TERMS_OF_USE} from "../../../constants/ExternalLinks";
import {useLocation, useNavigate} from "react-router-dom";
import {AuthContext, IAuth} from "../../../contexts/authContext";
import {DASHBOARD} from "../../../routes/routes-constant";
import ILocationState from "../../../types/ILocationState";

enum LOGIN_STATUS {
  DEFAULT,
  LOADING,
  SUCCESS,
  FAILURE,
}

interface IUserAuthInfo {
  userName: string;
  password: string;
}

const Login: FC = () => {
  const [consent, setConsent] = useState(true);
  const { signInWithEmail }: IAuth = useContext(AuthContext);
  const [loginStatus, setLoginStatus] = useState<LOGIN_STATUS>(LOGIN_STATUS.DEFAULT);
  const navigate = useNavigate();
  const state = useLocation().state as ILocationState;

  const handleLogin = async (value: IUserAuthInfo) => {
    try {
      const {userName, password} = value;
      setLoginStatus(LOGIN_STATUS.LOADING);
      await signInWithEmail(userName, password);
      setLoginStatus(LOGIN_STATUS.SUCCESS);
      navigate(state?.path || DASHBOARD);
    } catch (error) {
      setLoginStatus(LOGIN_STATUS.FAILURE);
    }
  };

  return (
    <Form
      name="signIn"
      onFinish={handleLogin}
    >
      <Form.Item
        name="userName"
        rules={[
          {
            required: true,
            message: 'Please input your user name',
          },
        ]}
      >
        <Input placeholder="user name" size="large" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'The password can not be empty.' }]}
      >
        <Input type="password" placeholder="Password" size="large" />
      </Form.Item>

      <Form.Item>
        <Checkbox
          checked={consent}
          onChange={(e) => setConsent(e.target.checked)}
        >
          I accept Telecare’s
          <a
            href={TERMS_OF_USE}
            target="_blank"
            rel="noreferrer"
          >
            {` Terms of Use `}
          </a>
          and
          <a
            href={PRIVACY_POLICY}
            target="_blank"
            rel="noreferrer"
          >
            {` Privacy Policy `}
          </a>
          .
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          disabled={!consent}
          type="primary"
          htmlType="submit"
          shape="round"
          size="large"
          block
          loading={loginStatus === LOGIN_STATUS.LOADING}
        >
          Sign In
        </Button>
      </Form.Item>

      {loginStatus === LOGIN_STATUS.FAILURE &&
        <Alert
        message="Error"
        description="Login failed, please check if your username and password are correct."
        type="error"
        showIcon
      />
      }
    </Form>
  );
};

export default Login;
