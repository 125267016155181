import React, {createContext, FC, useEffect, useState} from "react";
import cognitoService from "../libs/CognitoService";
import {resetAuthInfoInLocalStorage, setAuthTokenInLocalStorage} from "../utils/auth";

export enum AuthStatus {
  DEFAULT,
  LOADING,
  SIGNED_IN,
  SIGNED_OUT,
}

export interface IAuth {
  sessionInfo?: {
    username?: string;
    email?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string
  };
  attrInfo?: any;
  authStatus: AuthStatus;
  signInWithEmail?: any;
  signOut?: any;
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.DEFAULT,
};

export const AuthContext = createContext(defaultState);

export const AuthProvider: FC = ({children})=> {
  const [authStatus, setAuthStatus] = useState(AuthStatus.DEFAULT);
  const [sessionInfo, setSessionInfo] = useState({});
  const [attrInfo, setAttrInfo] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const session: any = await cognitoService.getSession();
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
        });
        setAuthTokenInLocalStorage(session);
        const attr: any = await cognitoService.getAttributes();
        setAttrInfo(attr);
        setAuthStatus(AuthStatus.SIGNED_IN);
      } catch (error) {
        setAuthStatus(AuthStatus.SIGNED_OUT);
      }
    })();
    // eslint-disable-next-line
  }, [authStatus]);

  async function signInWithEmail(username: string, password: string) {
    try {
      await cognitoService.signIn(username, password);
      setAuthStatus(AuthStatus.SIGNED_IN);
    } catch (err) {
      setAuthStatus(AuthStatus.SIGNED_OUT);
      throw err;
    }
  }

  function signOut() {
    cognitoService.signOut();
    setAuthStatus(AuthStatus.SIGNED_OUT);
    resetAuthInfoInLocalStorage();
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    signInWithEmail,
    signOut,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
