import React, {FC, useContext} from "react";
import {Spin} from "antd";
import styled from "styled-components";
import {Navigate, useLocation} from "react-router-dom";
import {SIGN_IN} from "./routes-constant";
import {AuthContext, AuthStatus, IAuth} from "../contexts/authContext";

const StyledSpin = styled(Spin)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const AuthIsSignedIn: FC = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext);
  const location = useLocation();

  if([AuthStatus.DEFAULT, AuthStatus.LOADING].includes(authStatus)) {
    return <StyledSpin tip="Loading..."/>;
  }

  if(authStatus === AuthStatus.SIGNED_IN) {
    return <>{children}</>;
  }

  return <Navigate to={SIGN_IN} replace state={{ path: location.pathname }}/>;
};

export default AuthIsSignedIn;
